<template>
    <div id="demandPassengerFlow">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="routeId" label="线路:">
                <!-- <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="dateType" label="日期类型:">
                <el-select v-model="form.dateType" placeholder="请选择">
                    <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="searchByYearMonth">
                <el-date-picker v-model="form.searchByYearMonth" value-format="yyyy-MM" type="month" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('form')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <el-row>
            <el-col :span="4" class="select_box">
                <el-radio v-model="radio" label="1">上行</el-radio>
                <el-radio v-model="radio" label="2">下行</el-radio>
            </el-col>
            <!-- <el-col :span="18" class="time_box">
                <span class="text_time"> 时间轴设置：</span>
                <el-radio v-model="form.a" label="1">15分钟</el-radio>
                <el-radio v-model="form.a" label="2">30分钟</el-radio>
                <el-radio v-model="form.a" label="3">45分钟</el-radio>
                <el-radio v-model="form.a" label="4">60分钟</el-radio>
            </el-col> -->
        </el-row>
        <echart :list="elist"></echart>
        <el-row>
            <el-col :span="12">
                <el-card>
                    <div slot="header">
                        <span>上行</span>
                    </div>
                    <div>
                        <jy-table :data="leftlist">
                            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                            <jy-table-column prop="routeName" label="所属线路"></jy-table-column>
                            <jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
                            <jy-table-column prop="timeFrame" label="时段"></jy-table-column>
                            <jy-table-column prop="vehicleNumValue" label="运营车辆数"></jy-table-column>
                            <jy-table-column prop="getOnTotal" label="月内日时间段平均上车人数"></jy-table-column>
                            <jy-table-column prop="getOffTotal" label="月内日时间段平均下车人数"></jy-table-column>
                            <jy-table-column prop="dir" label="月内日时间段平均车上人数"></jy-table-column>
                        </jy-table>
                        <!-- <div class="onCarBox">月内日均上车人数总计：{{onCar}}
                                <div class="secondBox">月内日均下车人数总计：{{onCar}}</div>
                            </div> -->
                        <!-- <div class="jy_pagination">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination>
                            </div> -->
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card>
                    <div slot="header">
                        <span>上行站点</span>
                    </div>
                    <div>
                        <jy-table :data="rightlist">
                            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                            <jy-table-column prop="routeName" label="所属线路"></jy-table-column>
                            <jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
                            <jy-table-column prop="siteName" label="站点名称"></jy-table-column>
                            <jy-table-column prop="getOnNum" label="月内日均上车人数"></jy-table-column>
                            <jy-table-column prop="getOffNum" label="月内日均下车人数"></jy-table-column>
                            <jy-table-column prop="passengers" label="月内日均车上人数"></jy-table-column>
                        </jy-table>
                        <!-- <div class="onCarBox">上车人数总计：{{onCar}}
                                <div class="secondBox">下车人数总计：{{onCar}}</div>
                            </div> -->
                        <!-- <div class="jy_pagination">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                                </el-pagination>
                            </div> -->
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import echart from '@/components/pages/admin/statisticalAnalysis/passengerFlow/demandPassengerFlow/charts'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            props: {
                children: 'children',
                label: 'text',
            },
            // echarts列表
            elist: [],
            radio: '1',

            form: {
                routeName: '',
                routeId: '',
                searchByYearMonth: '',
                dateType: '',
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            leftlist: [
                {
                    routeName: '',
                    timeFrame: '',
                    vehicleNumValue: '',
                    getOnTotal: '',
                    getOffTotal: '',
                    dir: '',
                },
            ],
            rightlist: [
                {
                    routeName: '',
                    siteName: '',
                    getOnNum: '',
                    getOffNum: '',
                    passengers: '',
                },
            ],
            // 上行
            upStreamTimeFramePfList: [],
            // 下行
            downStreamTimeFramePfList: [],
            // 上行站点
            upStreamSitePfList: [],
            // 下行站点
            downStreamSitePfList: [],
            typeoptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '工作日',
                },
                {
                    value: '2',
                    label: '周末',
                },
            ],
            routeOptions:[],
        }
    },
    created() {
        this.getList()
        this.getDate()
    },
    activated() {
        this.getRouteList()
    },
    watch: {
        radio: {
            handler(val) {
                if (val == '1') {
                    this.elist = this.upStreamTimeFramePfList
                    this.leftlist = this.upStreamTimeFramePfList
                    this.rightlist = this.upStreamSitePfList
                } else {
                    this.elist = this.downStreamTimeFramePfList
                    this.leftlist = this.downStreamTimeFramePfList
                    this.rightlist = this.downStreamSitePfList
                }
            },
            immediate: true,
        },
    },
    components: {
        echart,
        institutionsTree,
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        getDate() {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth()
            if (month == 0) {
                month = 12
            }
            if (month < 10) {
                month = '0' + month
            }
            var nowDate = year + '-' + month
            this.form.searchByYearMonth = nowDate
        },
        getList() {
            let option = {
                ...this.form,
                // pageIndex: this.pageIndex,
                // pageSize: this.pageSize
            }
            let url = '/statroutepftimesgdaily/queryPageList'
            this.$http.post(url, option).then(res => {
                if (res.retain == '1') {
                    this.upStreamTimeFramePfList = []
                    this.downStreamTimeFramePfList = []
                    this.upStreamSitePfList = []
                    this.downStreamSitePfList = []
                    this.$message({
                        message: res.error,
                        type: 'warning',
                    })
                } else {
                    this.upStreamTimeFramePfList =
                        res.detail.upStreamTimeFramePfList
                    this.downStreamTimeFramePfList =
                        res.detail.downStreamTimeFramePfList
                    this.upStreamSitePfList = res.detail.upStreamSitePfList
                    this.downStreamSitePfList = res.detail.downStreamSitePfList
                    this.radio = '1'
                    this.elist = this.upStreamTimeFramePfList
                    this.leftlist = this.upStreamTimeFramePfList
                    this.rightlist = this.upStreamSitePfList
                    // this.total = res.detail.total
                }
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.routeId = ''
            this.oncheck()
        },
        // 线路组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
    },
}
</script>
<style lang="scss" scoped="demandPassengerFlow">
#demandPassengerFlow {
    .select_box {
        margin-left: 50px;
        margin-bottom: 20px;
    }

    .time_box {
        text-align: right;

        .text_time {
            font-size: 14px;
        }
    }

    .onCarBox {
        padding-top: 10px;
        font-size: 12px;

        .secondBox {
            display: inline-block;
            margin-left: 20px;
        }
    }
}
</style>
