<template>
    <div class="times_statistics_charts">
        <div v-bind:style="boxStyle" ref="times_charts"></div>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            boxStyle: {},
            timeFrame: [],
            getOnTotal: [],
            getOffTotal: [],
            chart: null
        }
    },
    created() {
        this.$nextTick(() => {
            this.setStyle()
            this.setObserver()
        })
    },
    props: {
        list: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    watch: {
        list: {
            immediate: true,
            handler: function(val) {
                if (val) {
                    let l_s = [],
                        l_on = [],
                        l_off = []

                    val.forEach(item => {
                        l_s.push(item.timeFrame)
                        l_on.push(item.getOnTotal)
                        l_off.push(item.getOffTotal)
                    })
                    this.timeFrame = l_s
                    this.getOnTotal = l_on
                    this.getOffTotal = l_off
                    console.log(this.timeFrame)
                    console.log(this.getOnTotal)
                    console.log(this.getOffTotal)
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                } else {
                    this.timeFrame = []
                    this.getOnTotal = []
                    this.getOffTotal = []
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                }
            }
        }
    },
    methods: {
        setObserver() {
            let myObserve = new ResizeObserver(() => {
                echarts.dispose(this.chart)
                this.setStyle()
            })
            myObserve.observe(this.$el)
        },
        setChart() {
            var userChart = echarts.init(this.$refs['times_charts'])
            var option = {
                title: {
                    text: '客流需求及走向趋势分析',
                    x: 'center',
                    y: 'top',
                    textAlign: 'left'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['月均上车人数', '月均下车人数'],
                    left: 'center',
                    bottom: '10px'
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: this.timeFrame
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '月均上车人数',
                        type: 'line',
                        data: this.getOnTotal
                    },
                    {
                        name: '月均下车人数',
                        type: 'line',
                        data: this.getOffTotal
                    }
                ]
            }

            userChart.setOption(option)
            this.chart = userChart
        },
        setStyle() {
            let s = this.$refs['times_charts']
            let w = $(s).width()
            let h = w * 0.4 + 'px'
            console.log(w)
            this.boxStyle = {
                height: h
            }
            this.$nextTick(() => {
                this.setChart()
            })
        }
    }
}
</script>